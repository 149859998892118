<template>
    <standard-page :title="org.name" v-if="org">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'admin-home'}">Admin</router-link></span>
            <span><router-link :to="{ name: 'organizations'}">Organizations</router-link></span>
            <span v-if="org"><router-link :to="{ name: 'vieworg', params: {orgid: orgid}}">Organization: {{org.name | truncate(10, '......')}}</router-link></span>
            <span>Add Service</span>
        </template>
        <template v-slot:content>
            <div class="az-content-label mg-b-5">New Subscription</div>
            <div class="mg-b-20" v-html="$DEFINITIONS.admin.newSubscription"></div>
            <form @submit.prevent="handleSubmit">
                <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
                    <div class="row row-xs">
                        <div class="col-md-10">
                            <multiselect :class="{'is-invalid': submitted && errors.has('service')}"
                                         :options="services"
                                         :selectLabel="''"
                                         class="form-control rounded"
                                         data-vv-as="Service"
                                         id="service"
                                         label="Service"
                                         name="service"
                                         open-direction="bottom"
                                         placeholder="Select Service"
                                         v-model="service"
                                         v-validate="'required'">
                                <template slot="singleLabel" slot-scope="props">
                                    {{props.option.name}}
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <h6>{{ props.option.name | capitalize }}</h6>
                                        <span>{{ props.option.description }}</span>
                                    </div>
                                </template>
                            </multiselect>
                            <div class="invalid-feedback" v-if="submitted && errors.has('service')">{{ errors.first('service') }}</div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </standard-page>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import EventBus from "@/event-bus";

    export default {
        name: "AddService",
        props: ['orgid'],
        components: {Multiselect},
        data() {
            return {
                submitted: false,
                services: [],
                subscriptions: [],
                service: null,
                org: null
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getSubscriptions();
                await this.getServices();
                await this.getOrg();
            },
            async getSubscriptions() {
                try {
                    let {data} = await this.$accounts.get(`/accounts/orgs/${this.orgid}/subscriptions?page=0&per_page=100`);
                    this.subscriptions = data.services;
                } catch (e) {
                    console.log(e)
                }
            },
            async getServices() {
                try {
                    let {data} = await this.$accounts.get(`/accounts/services`);
                    let services = data.services;
                    for (let i = 0; i < this.subscriptions.length; i++) {
                        let item = this.subscriptions[i];
                        services.splice(services.findIndex(({service}) => service === item.service), 1);
                    }
                    this.services = services;
                } catch (e) {
                    console.log(e)
                }
            },
            async getOrg() {
                try {
                    let {data} = await this.$accounts.get(`/accounts/orgs/${this.orgid}`);
                    this.org = data;
                } catch (e) {

                }
            },
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                this.subscribe()
                            }
                        });
            },
            async subscribe() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$accounts.post(`/accounts/services/${this.service.service}/subscribe`, {
                        org: Number(this.orgid)
                    });
                    this.$toastr.s(`the service Successfully subscribed!`, 'Success');
                    this.service = null;
                    await this.$router.push({name: 'vieworg'});
                } catch (e) {
                    this.$toastr.e(`this service subscribe failed!`, 'Error');
                } finally {
                    EventBus.$emit('closeLoader');
                }
            }
        }
    }
</script>

<style scoped>

</style>